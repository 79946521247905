import React, { PropsWithChildren } from 'react';
import Header from './Header';
import styled from 'styled-components';
import px2rem from '@/utils/px2rem';

const HEADER_HEIGHT = 72;

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.bg1};
`;

const ContentWrapper = styled.div`
  max-width: 1920px;
  padding: 0 ${px2rem(32)};
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <Header height={HEADER_HEIGHT} />
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
};

export default Layout;
