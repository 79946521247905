import { CDN_URL } from "@/configs";
import { ROUTE_PATH } from "@/constants/route-path";
import { gsap } from "gsap";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { Wrapper } from "./Header.styled";
import MenuMobile from "./MenuMobile";
import WalletHeader from "./Wallet";
import { useWindowSize } from "@trustless-computer/dapp-core";
import IconSVG from "@/components/IconSVG";

const Header = ({ height }: { height: number }) => {
  const refMenu = useRef<HTMLDivElement | null>(null);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const { mobileScreen } = useWindowSize();

  useEffect(() => {
    if (refMenu.current) {
      if (isOpenMenu) {
        gsap.to(refMenu.current, { x: 0, duration: 0.6, ease: "power3.inOut" });
      } else {
        gsap.to(refMenu.current, {
          x: "100%",
          duration: 0.6,
          ease: "power3.inOut",
        });
      }
    }
  }, [isOpenMenu]);

  return (
    <Wrapper style={{ height }}>
      <div className="content">
        <Link className="logo" href={ROUTE_PATH.ABOUT}>
          {mobileScreen && (
            <IconSVG src={`${CDN_URL}/icons/logo-photos.svg`} maxWidth="40" />
          )}
          {!mobileScreen && (
            <IconSVG src={`${CDN_URL}/images/photo-logo-v2.svg`} />
          )}
        </Link>

        <MenuMobile ref={refMenu} onCloseMenu={() => setIsOpenMenu(false)} />
        <div className="rightContainer">

          <WalletHeader />
          <button className="btnMenuMobile" onClick={() => setIsOpenMenu(true)}>
            <img src={`${CDN_URL}/icons/ic_hambuger.svg`} />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
